import { RequestContext, ResponseResult } from '../layer/types';
import { CacheValue, LatestHitAttempt } from './types';
import { retry, mapRequest } from './utils';

export default class TTLCache {
  private cache: Map<string, CacheValue>;

  private readonly ttl: number;

  // it's used as a lookup table to check consecutive requests
  private readonly latestAttempts: LatestHitAttempt[];

  private readonly MAX_LATEST_ATTEMPTS = 5;

  private readonly attemptTTL: number;

  private readonly MAX_CACHE_SIZE = 25;

  constructor(ttl = 900) {
    this.cache = new Map();
    this.ttl = ttl;
    this.attemptTTL = ttl - 50;
    this.latestAttempts = [];
  }

  private pushLatestHitAttemps(key: string): void {
    if (this.latestAttempts.length === this.MAX_LATEST_ATTEMPTS) {
      this.latestAttempts.pop();
    }
    this.latestAttempts.unshift({ key: key, ttl: Date.now() + this.attemptTTL });
  }

  private shouldAttempt(key: string): boolean {
    return this.latestAttempts.find(attempt => attempt.key === key && Date.now() < attempt.ttl) ? true : false;
  }

  private generateCacheKey(request: RequestContext): string {
    mapRequest(request as unknown as Record<string, unknown>);
    const { url, ...rest } = request;
    return `${url}-${JSON.stringify(rest)}`;
  }

  public add(request: RequestContext, response: ResponseResult<unknown>): void {
    if (this.size() >= this.MAX_CACHE_SIZE) {
      this.clear();
    }

    this.cache.set(
      this.generateCacheKey(request),
      {
        response,
        expirationTime: Date.now() + this.ttl
      }
    );
  }

  public async get(request: RequestContext): Promise<ResponseResult<unknown> | null> {
    const key = this.generateCacheKey(request);

    const shouldAttempt = this.shouldAttempt(key);
    this.pushLatestHitAttemps(key);

    let cacheItem: CacheValue<unknown> | undefined = this.cache.get(key);

    // it's a retry mechanism to handle consecutive hit attempts
    // to decrase the posiblity of worst case scenarios, a lookup table is used
    // and early exists are implemented
    if (!cacheItem && shouldAttempt) {
      cacheItem = await retry(() => new Promise((resolve, reject) => {
        const item = this.cache.get(key);

        if (item) {
          if (this.isValueValid(item)) resolve(item);
          else {
            this.cache.delete(key);
            resolve(undefined);
          }
        }

        reject();
      }), 40, 50);
    }

    if (cacheItem && this.isValueValid(cacheItem)) {
      return cacheItem.response;
    }

    this.cache.delete(key);
    return null;
  }

  public remove(request: RequestContext): void {
    this.cache.delete(this.generateCacheKey(request));
  }

  public clear(): void {
    this.cache.clear();
  }

  public size(): number {
    return this.cache.size;
  }

  private isValueValid(cacheItem: CacheValue<unknown> | undefined): boolean {
    if (!cacheItem) return false;
    return Date.now() < cacheItem.expirationTime;
  }
}
