/* global navigator */
import Axios from 'axios';
import qs from 'querystring';
import { getPossibleTimeZone, handleCustomNavigation } from '@jotforminc/utils';
import { openOFACModal } from '../components/OFACModal';

export const getScreenResolution = () => {
  const { screen: { availWidth, availHeight } } = window;
  if (availWidth && availHeight) {
    return `${availWidth}x${availHeight}`;
  }
  return '';
};

export const getSocialLoginOptionsByType = type => {
  const options = {
    google: { tokenKey: 'gtoken', action: 'loginWithGoogle' },
    googleOneTap: { tokenKey: 'gtoken', action: 'loginWithGoogle' },
    facebook: { tokenKey: 'ftoken', action: 'loginWithFacebook' },
    microsoft: { tokenKey: 'mtoken', action: 'loginWithMicrosoft' },
    salesforce: { tokenKey: 'stoken', action: 'loginWithSalesforce' }
  };

  return options[type];
};

export const pushDataLayerEvent = ({ event, eventAction }) => {
  if (window.dataLayer && (typeof window.dataLayer === 'object')) {
    window.dataLayer.push({ event, eventAction });
  }
};

const handleSignupVariationTest = username => {
  if (!window.dataLayer || !window.signupEvents) return;
  if (Math.random() < 0.5) {
    pushDataLayerEvent({
      event: 'Signup',
      eventAction: 'Complete-Remarketing'
    });
    if (window.location.href.indexOf('jotform.pro') < 0) {
      window.signupEvents.tick({
        actor: username ? username : 'unknown',
        action: 'Signup',
        target: 'Complete-Remarketing'
      });
    }
  } else if (window.location.href.indexOf('jotform.pro') < 0) {
    window.signupEvents.tick({
      actor: username ? username : 'unknown',
      action: 'Signup',
      target: 'Complete-Remarketing-Passive'
    });
  }
};

export const handleSignupCompletedEvents = (username, type) => {
  if (typeof window.signupEvents === 'undefined' && typeof username !== 'undefined' && typeof JotFormActions !== 'undefined') {
    window.signupEvents = window.JotFormActions('jotform-signup-events');
    handleSignupVariationTest(username);
  }

  pushDataLayerEvent({
    event: 'Signup',
    eventAction: 'Complete'
  });

  if (type) {
    pushDataLayerEvent({
      event: `sign_up_social_${type}`
    });
  }

  window.uetq = window.uetq || [];
  window.uetq.push({
    ec: 'Signup',
    ea: 'Complete'
  });
  window.dotq = window.dotq || [];
  window.dotq.push({
    projectId: '10000',
    properties: {
      pixelId: '10027312',
      qstrings: {
        et: 'custom',
        ec: 'Signup',
        ea: 'Completed'
      }
    }
  });
};

export const forceUserSocialLogin = (
  type = '',
  token = '',
  onSuccess = f => f,
  onFailure = f => f,
  appName = '',
  tfaResponseCallback = f => f,
  tfaVerificationToken,
  tfaMethod,
  buttonNames = {},
  socialLoginVerificationHash = '',
  onSocialTermsRequired = f => f,
  enableSocialTerms = false,
  eaResponseCallback = f => f,
  eaVerificationToken,
  newGoogleFlow,
  ofacModalRef
) => {
  const config = getSocialLoginOptionsByType(type);
  if (!config) {
    // eslint-disable-next-line
    console.error('Unsupported social login type', type);
    return;
  }

  const actionData = {
    action: config.action,
    [config.tokenKey]: token
  };

  const defaultButtonName = appName ? `${type}-${appName}` : '';
  const buttonName = buttonNames[type] ? buttonNames[type] : defaultButtonName;

  Axios.post('/server.php', qs.stringify({
    ...(actionData),
    ...(tfaVerificationToken && { token: tfaVerificationToken }),
    ...(eaVerificationToken && { eaToken: eaVerificationToken }),
    ...(socialLoginVerificationHash && { socialLoginHash: socialLoginVerificationHash }),
    ...(enableSocialTerms && { enableSocialTerms }),
    tokenType: tfaMethod,
    language: navigator.language,
    timezone: getPossibleTimeZone(),
    screen_resolution: getScreenResolution(),
    location: global.location.pathname,
    button_name: buttonName,
    ref_app: appName,
    eat: 1,
    return: 1,
    ...(newGoogleFlow && { newGoogleFlow })
  }), {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
    .then(async ({ data: responseData }) => {
      const {
        success, message, token: tfaToken, newUser, user, socialLoginHash, emailAuthToken, idToken
      } = responseData;
      if (!success) {
        // eslint-disable-next-line
        console.error('Couldn\'t login', message);
      }

      if (socialLoginHash) {
        const { fullName, email, imageUrl } = responseData;
        onSocialTermsRequired({
          socialLoginHash,
          socialUserProfile: { fullName, email, imageUrl },
          idToken
        });
        return;
      }

      if (tfaToken) {
        tfaResponseCallback(responseData);
        return;
      }

      if (emailAuthToken) {
        eaResponseCallback(responseData);
        return;
      }

      if (newUser) {
        handleSignupCompletedEvents(user.username, type);
      }

      onSuccess({ data: responseData });
    })
    .catch(error => {
      // eslint-disable-next-line
      const { response: { data: { error: message = '' } = {} } = {} } = error;
      if (message === "Sorry, you can't signup from this country.") {
        openOFACModal(ofacModalRef);
      }

      if (message?.toLowerCase()?.includes('suspended')) {
        return handleCustomNavigation('/account-suspended', '_self', true);
      }

      if (typeof onFailure === 'function') {
        onFailure(error);
      }

      // eslint-disable-next-line no-console
      console.error('--Couldn\'t login', error);
    });
};

export const forceUserLoginRequest = async (username, password, appName, recaptcha, tfaToken, tfaTokenType, buttonNames, eaToken, rememberDevice) => {
  try {
    /**
     * TODO: Base url fix required
     *
     * This method is currently dedicated for applications (reports, sheets, inbox etc.).
     * Therefore we do not use `apiURL` like on other requests and leave the url as relative which may cause problems on
     * forms due to cached urls like 'form.jotform.com'.
     *
     * To sum up, please do not use this method on forms. If you are consider doing it then fix the url issue mentioned at above.
     */
    const response = await Axios.post('/server.php', qs.stringify({
      action: 'login',
      ...(!tfaToken && { password: password }),
      remember: true,
      ...(!tfaToken && { username: username }),
      ref_app: appName,
      recaptcha,
      language: global.navigator.language,
      timezone: getPossibleTimeZone(),
      screen_resolution: getScreenResolution(),
      location: global.location.pathname,
      button_name: buttonNames.emailLogin || `emailLogin-${appName}`,
      ...(tfaToken && { token: tfaToken }),
      ...(eaToken && { eaToken: eaToken }),
      tokenType: tfaTokenType,
      rememberDevice: rememberDevice,
      ...(!tfaToken && { eat: '1' }) // email auth parameter
    }), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (response.data.token) return [false, { content: response.data }, true, false]; // 2fa
    if (response?.data?.emailAuthToken) return [false, { content: response.data }, false, true]; // email authentication

    return [true, { content: response.data.user, responseCode: response.status }];
  } catch (error) {
    return [false, error.response || error.message];
  }
};

export const handleLoginHelper = async ({
  username,
  password,
  tfaToken = '',
  tfaTokenType = '',
  regularLoginRequest,
  forceUser,
  handleLoginSuccess,
  handleNavigationChangeRequest,
  screenToNavigate = 'epilogue',
  shouldCallNavigate = true,
  appName,
  recaptcha,
  handleTFAResponse,
  buttonNames,
  eaToken,
  handleEAResponse,
  rememberDevice,
  isRecovery
}) => {
  // Added temporary user list for testing purposes, will be deleted later when 2fa is enabled for all BSG users
  const loginFunction = forceUser ? forceUserLoginRequest : regularLoginRequest;

  const [loginSuccess, loginResponse, tfaResponse = false, eaResponse = false] = await loginFunction(
    username, password, appName, recaptcha, tfaToken, tfaTokenType, buttonNames, eaToken, rememberDevice
  );

  if (tfaResponse) {
    const { content } = loginResponse;
    handleTFAResponse(content, username, password);
  }

  if (eaResponse) {
    const { content } = loginResponse;
    handleEAResponse(content, username, password);
  }

  if (!loginSuccess && typeof loginResponse?.message === 'string' && loginResponse?.message?.toLowerCase()?.includes('suspended')) {
    return handleCustomNavigation('/account-suspended', '_self', true);
  }

  if (!loginSuccess) return [loginSuccess, loginResponse, tfaResponse, eaResponse];

  if (isRecovery) return handleCustomNavigation('/myaccount/security', '_self', true);

  handleLoginSuccess(loginResponse);
  if (shouldCallNavigate) handleNavigationChangeRequest(screenToNavigate);
};

export const getTermsOfUseLinkByLanguage = isTurkish => (
  isTurkish
    ? 'https://www.jotform.com/hizmet-kosullari'
    : 'https://www.jotform.com/terms'
);

export const getPrivacyLinkByLanguage = isTurkish => (
  isTurkish
    ? 'https://www.jotform.com/gizlilik-politikasi'
    : 'https://www.jotform.com/privacy'
);

export const isTurkishLanguage = (user = {}) => {
  if (!user) return false;
  const { language, location: { country_code: countryCode } = {} } = user;
  return countryCode === 'TR' || language === 'tr-TR';
};

export const createGoogleOneTapContainerEl = () => {
  if (!document.querySelector('#oneTapLoginArea')) {
    const container = document.createElement('div');
    container.setAttribute('id', 'oneTapLoginArea');
    document.getElementsByTagName('body')[0].appendChild(container);
  }
};

export const hideGoogleOneTapContainerEl = () => {
  const container = document.querySelector('#oneTapLoginArea');
  if (container) {
    container.style.display = 'none';
  }
};
