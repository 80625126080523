/* eslint-disable @jotforminc/no-native-button */
/* eslint-disable complexity,react/sort-comp */
import React, { Component } from 'react';
import {
  bool,
  string,
  func,
  arrayOf,
  shape
} from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import { RequestLayer, Interceptors, getAPIURL } from '@jotforminc/request-layer';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { Texts } from '@jotforminc/constants';
import { listenEnter, getUrlParameter, handleCustomNavigation } from '@jotforminc/utils';

import BackIcon from '../temporary-duplicate/assets/svg/icon_back.svg';
import ErrorAlert from './ErrorAlert';
import SocialButtons from './SocialButtons';
import FacebookButton from './FacebookButton';
import GoogleButton from './GoogleButton';
import MicrosoftButton from './MicrosoftButton';
import { errorNormalizer } from '../utils/helper';
import SSOLoginOptions from './SSOLoginOptions';
import { EMAIL_ADDRESS, ENTER_EMAIL_ADDRESS, OR_LOGIN_WITH } from '../constants';

const invalidInputText = Texts.FIELD_REQUIRED;
const invalidEmailInputText = Texts.EMAIL_FIELD_REQUIRED;
const invalidPassInputText = Texts.PASS_FIELD_REQUIRED;
const userIsSSOError = Texts.USER_IS_SSO_ERROR;
const unexpectedErrorText = Texts.ERROR_TRY_AGAIN;

export default class LoginOptions extends Component {
  constructor(props) {
    super(props);
    this.isEnterprise = isEnterprise();
    const emailParam = getUrlParameter('username');
    const skipEmailVerification = getUrlParameter('skipEmailVerification');
    const { ssoLoginOptions } = props;
    this.state = {
      email: (!emailParam || emailParam === '') ? props.loginPredefinedEmail : emailParam,
      pass: '',
      emailValid: true,
      ssoErrorMessage: '',
      passValid: true,
      loginErrorMsg: '',
      recaptcha: false,
      isLoginLoading: false,
      showSalesforceLogin: false,
      isSSOControlStep: this.isEnterprise && ssoLoginOptions.length > 0 && !skipEmailVerification,
      isSSOControlLoading: false
    };
    this.layer = new RequestLayer(getAPIURL(), {
      interceptorConfig: {
        teamID: global.teamID,
        customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
      }
    });

    this.socialLoginParams = {
      facebook: getUrlParameter('facebook') === '1',
      google: getUrlParameter('google') === '1',
      microsoft: getUrlParameter('microsoft') === '1'
    };

    this.emailInputRef = React.createRef();
    this.passInputRef = React.createRef();
  }

  componentDidMount() {
    const { registerMsAbTestAction, isOnboardingFlow } = this.props;
    if (!isOnboardingFlow && window.innerWidth > 480) {
      this.emailInputRef.current?.focus();
    }
    registerMsAbTestAction({ action: 'seen', target: 'loginOptions' });

    this.setState({
      showSalesforceLogin: !this.isEnterprise
    });
  }

  componentDidUpdate(prevProps) {
    const { externalErrorMessage } = this.props;

    if (externalErrorMessage !== prevProps.externalErrorMessage && this.isEnterprise) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loginErrorMsg: externalErrorMessage
      });
    }
  }

  /**
   * TODO: This is temp solution.
   * For more information please contact the commit owner.
   */
  get showForgotPassword() {
    const {
      isSSOControlStep
    } = this.state;

    return !isSSOControlStep;
  }

  validate = input => {
    const { [input]: value } = this.state;
    this.setState({
      [`${input}Valid`]: !!value // inputs shouldn't be empty
    });
  };

  handleInputChange = input => e => {
    const { value } = e.target;
    const { setExternalErrorMessage } = this.props;
    this.setState({ [input]: value }, () => {
      this.validate(input);
    });
    setExternalErrorMessage('');
    this.setState({
      loginErrorMsg: ''
    });
  };

  validateAll = async (fields = ['email', 'pass']) => {
    await Promise.all(fields.map(input => {
      return Promise.resolve(this.validate(input));
    }));
    const { emailValid, passValid } = this.state;
    if (!emailValid) {
      this.emailInputRef.current.focus();
    } else if (!passValid) {
      this.passInputRef.current.focus();
    }
    return Promise.resolve(emailValid && passValid);
  };

  handleLogin = async (event, recaptcha) => {
    const validationResult = await this.validateAll();
    this.setState({ isLoginLoading: true });
    if (!validationResult) {
      this.setState({ isLoginLoading: false });
      return;
    }
    const { onLoginClick } = this.props;
    const { email, pass } = this.state;
    const [success = true, { message = 'An error occured.', data: { error } = {} } = {}, tfaResponse = false] = await onLoginClick(email, pass, recaptcha) || [];

    if (!success && !tfaResponse) {
      this.setState({ isLoginLoading: false });
      const errorMessage = error ? error : message;

      if (errorMessage === 'REQUIRE_CAPTCHA') {
        return this.setState({ recaptcha: true });
      }

      if (error?.toLowerCase()?.includes('suspended')) {
        return handleCustomNavigation('/account-suspended', '_self', true);
      }

      this.setState({
        loginErrorMsg: errorMessage,
        recaptcha: false
      });
    }
  };

  /**
   * Check if the user with username/email is an SSO user,
   * if so do not allow them to the password view, if not
   * show the password field.
   */
  handleSSOControl = async () => {
    const validationResult = await this.validateAll(['email']);
    this.setState({
      ssoErrorMessage: '',
      isSSOControlLoading: true
    });
    if (!validationResult) {
      this.setState({ isSSOControlLoading: false });
      return;
    }
    const { email } = this.state;
    const { getUserSSOStatus } = this.props;
    try {
      const { content: { isSSOUser } } = await getUserSSOStatus(email);
      if (isSSOUser) {
        this.setState({
          ssoErrorMessage: userIsSSOError
        });
      } else {
      // Ask the user for a password.
        this.setState({
          isSSOControlStep: false
        });
      }
    } catch (e) {
      if (e.message === 'Rate limit exceeded') {
        // Gracefully degrade and show password
        // if we hit rate limit.
        this.setState({
          isSSOControlStep: false
        });
      } else {
        // Set to an error state.
        // for actual errors.
        this.setState({
          emailValid: false,
          ssoErrorMessage: unexpectedErrorText
        });
      }
    } finally {
      // By this point whether or not we had an error loading
      // has finished.
      this.setState({ isSSOControlLoading: false });
    }
  };

  handleKeyDown = e => {
    const {
      isSSOControlStep,
      isLoginLoading,
      isSSOControlLoading
    } = this.state;
    // To avoid users spamming enter.
    const loading = isLoginLoading || isSSOControlLoading;
    if (!loading) {
      return listenEnter(e, isSSOControlStep
        ? this.handleSSOControl
        : this.handleLogin
      );
    }
  };

  /**
   * Get the error message to be shown under
   * email/username input, if there is any.
   */
  get emailInputErrorMessage() {
    const {
      isSSOControlStep,
      loginErrorMsg,
      emailValid,
      ssoErrorMessage
    } = this.state;
    if (isSSOControlStep && loginErrorMsg) {
      // Otherwise this would be displayed under password which isn't
      // visible.
      return loginErrorMsg;
    }

    if (!emailValid) {
      return t(invalidEmailInputText);
    }

    if (ssoErrorMessage) {
      return t(ssoErrorMessage);
    }

    return '';
  }

  redirectToEnterpriseLogin() {
    try {
      if (window.opener.isSalesforceAdmin) {
        // https://stackoverflow.com/questions/71154547/window-opener-reference-lost-in-firefox
        // eslint-disable-next-line @jotforminc/no-location-href-assignment
        window.location.href = '/platform/auth?registrationType=oauth&client_id=Salesforce&access_type=full&auth_type=login&popup=1&newEnterpriseStarter=1';
      } else {
        // eslint-disable-next-line @jotforminc/no-location-href-assignment
        window.location.href = '/platform/auth?registrationType=oauth&client_id=Salesforce&access_type=full&auth_type=login&popup=1&contactAdminError=1';
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  render() {
    const {
      onNavigationChangeRequest,
      onSalesforceLoginClick,
      onGoogleLoginClick,
      onFBLoginClick,
      onMicrosoftLoginClick,
      preventBack,
      thereSocialError,
      thereErrorMessage,
      loginButtonText,
      loginWelcomeText,
      loginDescriptionText,
      onAppleLoginClick,
      hideSignUp,
      greetingDescription,
      registerMsAbTestAction,
      logoSource,
      readonlyEmail,
      showJotformLogo,
      showEnterpriseLogin,
      isSwsoTestVariant,
      forceShowBack,
      fromSACL,
      ssoLoginOptions,
      onSSOLoginSuccess,
      onSSOLoginFail,
      isOnboardingFlow,
      isOnboardingTestVariantReversed
    } = this.props;

    const {
      email,
      pass,
      passValid,
      loginErrorMsg,
      recaptcha,
      isLoginLoading,
      showSalesforceLogin,
      isSSOControlStep,
      isSSOControlLoading
    } = this.state;

    const emailErrorMessage = this.emailInputErrorMessage;
    const loginLogoSource = this.isEnterprise ? logoSource : 'https://www.jotform.com/resources/assets/logo-nb/jotform-logo-white-400x100.png';
    const showLogo = (this.isEnterprise && loginLogoSource) || (!this.isEnterprise && showJotformLogo);

    return (
      <div className={classNames('xcl-content xcl-nb-content', { isOnboardingFlow, isOnboardingTestVariantReversed })}>
        {showLogo && <img src={loginLogoSource} alt="Logo" className="xcl-companyLogo" />}
        {loginWelcomeText && loginWelcomeText.length > 0 && (<h2 className="m2bt isLoginWelcomeText">{t(loginWelcomeText)}</h2>)}
        {(greetingDescription || loginDescriptionText) && (<p className="isDescription test-variant">{t(greetingDescription || loginDescriptionText)}</p>)}
        <div className={classNames('xcl-cw loginContainer', { 'social-login-5-item': showSalesforceLogin })} id="loginBox">
          {!this.isEnterprise
          && (
            <>
              <div className="socialButtonWrapper vertical">
                <span className="microsoft-signup-test-login-title">{t(Texts.LOGIN_WITH)}</span>
                <div className="microsoft-signup-test-wrapper horizontal">
                  {Object.values(this.socialLoginParams).every(q => q === false) && (
                    <SocialButtons
                      registerMsAbTestAction={registerMsAbTestAction}
                      showSalesforceLogin={showSalesforceLogin}
                      onAppleLoginClick={onAppleLoginClick}
                      onMicrosoftLoginClick={onMicrosoftLoginClick}
                      onFBLoginClick={onFBLoginClick}
                      onSalesforceLoginClick={onSalesforceLoginClick}
                      onGoogleLoginClick={onGoogleLoginClick}
                    />
                  )}
                  {this.socialLoginParams.facebook && (
                    <FacebookButton
                      text={Texts.LOGIN_FACEBOOK}
                      onClick={() => onFBLoginClick()}
                    />
                  )}
                  {this.socialLoginParams.google && (
                    <GoogleButton
                      text={Texts.LOGIN_GOOGLE}
                      onClick={() => onGoogleLoginClick()}
                    />
                  )}
                  {this.socialLoginParams.microsoft && (
                    <MicrosoftButton
                      text={Texts.LOGIN_MICROSOFT}
                      onClick={() => onMicrosoftLoginClick()}
                    />
                  )}
                </div>
              </div>
              {thereSocialError
                && (
                <div role="alert" className="xcl-lbl-err xcl-lbl-error-social isVisible ">
                  {t(errorNormalizer(thereErrorMessage))}
                </div>
                )}
              <div className="xcl-divider"><span>{!isOnboardingFlow ? t(Texts.OR_KEY) : t(OR_LOGIN_WITH)}</span></div>
            </>
          )}
          <div className="xcl-form forLogin js-loginForm" id="loginForm">
            {
              ssoLoginOptions.length > 0 && (
                <SSOLoginOptions
                  ssoLoginOptions={ssoLoginOptions}
                  onSSOLoginSuccess={onSSOLoginSuccess}
                  onSSOLoginFail={onSSOLoginFail}
                  fromSACL={fromSACL}
                />
              )
            }
            <div className="xcl-field-wr" id="usernameField">
              <label className="xcl-lbl" htmlFor="username">{!isOnboardingFlow ? t(Texts.USERNAME_EMAIL) : t(EMAIL_ADDRESS)}</label>
              <input
                className={`xcl-inp${(emailErrorMessage ? ' errored' : '')}`}
                type="email"
                name="email"
                value={email}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleInputChange('email')}
                id="username"
                readOnly={readonlyEmail || false}
                ref={this.emailInputRef}
                aria-label={t(emailErrorMessage)}
                placeholder={isOnboardingFlow ? t(ENTER_EMAIL_ADDRESS) : ''}
              />
              <div role="alert" className={`xcl-lbl-err ${emailErrorMessage ? 'isVisible' : null}`} id="usernameErrorDiv">
                {emailErrorMessage}
              </div>
            </div>
            {!isSSOControlStep
            && (
            <div className="xcl-field-wr" id="passwordField">
              <label className="xcl-lbl" htmlFor="password">{t(Texts.PASSWORD_TEXT)}</label>
              <input
                className={`xcl-inp${(passValid ? '' : ' errored')}`}
                type="password"
                name="pass"
                value={pass}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleInputChange('pass')}
                id="password"
                ref={this.passInputRef}
                aria-label={!passValid ? t(invalidPassInputText) : null}
              />
              <div role="alert" className={`xcl-lbl-err ${passValid ? null : 'isVisible'}`} id="passwordErrorDiv">
                {passValid ? null : t(invalidInputText)}
              </div>
              {!hideSignUp && this.showForgotPassword && (
                <button
                  type='button'
                  className="xcl-link-fp"
                  onClick={() => onNavigationChangeRequest('forgotPassword')}
                  id="forgotPasswordButton"
                >
                  {t(Texts.FORGOT_PASSWORD)}
                </button>
              )}
              {loginErrorMsg ? (
                <ErrorAlert message={t(loginErrorMsg)} />
              ) : null}
            </div>
            )}

            {recaptcha ? (
              <div id="recaptcha-container" className='lf-recaptcha-container'>
                <ReCAPTCHA sitekey="6LdU3CgUAAAAAB0nnFM3M3T0sy707slYYU51RroJ" onChange={recaptcha_ => this.handleLogin(null, recaptcha_)} />
              </div>
            ) : null}

            {isSSOControlStep ? (
              <button
                className="xcl-button-gr button-signin test_loginButton"
                id="signinButton"
                onClick={this.handleSSOControl}
                type="button"
                disabled={isLoginLoading}
              >
                {isSSOControlLoading
                  ? (
                    <div className="button-loader">
                      <div className="button-loader-item" />
                      <div className="button-loader-item" />
                      <div className="button-loader-item" />
                    </div>
                  )
                  : t('Continue')}
              </button>
            ) : (
              <button
                className="xcl-button-gr button-signin test_loginButton"
                id="signinButton"
                onClick={this.handleLogin}
                type="button"
                disabled={isLoginLoading}
              >
                {isLoginLoading
                  ? (
                    <div className="button-loader">
                      <div className="button-loader-item" />
                      <div className="button-loader-item" />
                      <div className="button-loader-item" />
                    </div>
                  )
                  : t(loginButtonText)}
              </button>
            )}
            {hideSignUp && this.showForgotPassword && (
              <div>
                <button
                  type="button"
                  className="xcl-link-fp"
                  onClick={() => onNavigationChangeRequest('forgotPassword')}
                  id="forgotPasswordButton"
                >
                  {t(Texts.FORGOT_PASSWORD)}
                </button>
              </div>
            )}
            {!isOnboardingFlow && !showEnterpriseLogin && !hideSignUp && (
              <div className="m1b alignBottom">
                {t(Texts.DONT_HAVE_ACCOUNT)}
                <button
                  type="button"
                  onClick={() => {
                    let upcomingScreen = !isSwsoTestVariant ? 'signupOptions' : 'signupWithSocialOpts';
                    if (this.isEnterprise && !fromSACL) {
                      upcomingScreen = 'signupWithEmail';
                    }
                    onNavigationChangeRequest(upcomingScreen);
                  }}
                >
                  {t(Texts.SIGN_UP)}
                </button>
              </div>
            )}
            {showEnterpriseLogin && (
              <div className="m1b alignBottom">
                {t(Texts.ARE_YOU_JOTFORM_ENTERPRISE_USER)}
                <button
                  type="button"
                  onClick={() => this.redirectToEnterpriseLogin()}
                >
                  {t(Texts.LOGIN_TO_JOTFORM_ENTERPRISE)}
                </button>
              </div>
            )}
          </div>
          {!preventBack && (
            <button
              className={`xcl-button-back forSignupOptions${forceShowBack ? ' forceShowBack' : ''}`}
              onClick={() => onNavigationChangeRequest(!isSwsoTestVariant ? 'signupOptions' : 'signupWithSocialOpts')}
              type="button"
            >
              <BackIcon />
              {t(Texts.BACK_KEY)}
            </button>
          )}
        </div>
      </div>
    );
  }
}

LoginOptions.propTypes = {
  onNavigationChangeRequest: func.isRequired,
  onSalesforceLoginClick: func.isRequired,
  onGoogleLoginClick: func.isRequired,
  onFBLoginClick: func.isRequired,
  onMicrosoftLoginClick: func.isRequired,
  onAppleLoginClick: func,
  onLoginClick: func.isRequired,
  loginPredefinedEmail: string,
  preventBack: bool,
  thereSocialError: bool,
  externalErrorMessage: string,
  setExternalErrorMessage: func,
  loginButtonText: string,
  loginWelcomeText: string,
  loginDescriptionText: string,
  greetingDescription: string,
  registerMsAbTestAction: func,
  logoSource: string,
  readonlyEmail: bool,
  showJotformLogo: bool,
  showEnterpriseLogin: bool,
  hideSignUp: bool,
  isSwsoTestVariant: bool,
  forceShowBack: bool,
  fromSACL: bool,
  ssoLoginOptions: arrayOf(shape({
    name: string,
    logoURL: string,
    configKey: string,
    idpName: string
  })),
  onSSOLoginSuccess: func,
  onSSOLoginFail: func,
  thereErrorMessage: string,
  isOnboardingFlow: bool,
  getUserSSOStatus: func,
  isOnboardingTestVariantReversed: bool
};

LoginOptions.defaultProps = {
  loginPredefinedEmail: '',
  preventBack: false,
  thereSocialError: false,
  externalErrorMessage: '',
  setExternalErrorMessage: f => f,
  loginButtonText: Texts.LOGIN_CAPITAL,
  loginWelcomeText: Texts.WELCOME_BACK,
  loginDescriptionText: '',
  onAppleLoginClick: f => f,
  greetingDescription: '',
  registerMsAbTestAction: f => f,
  logoSource: '',
  readonlyEmail: false,
  showJotformLogo: false,
  showEnterpriseLogin: false,
  hideSignUp: false,
  isSwsoTestVariant: false,
  forceShowBack: false,
  fromSACL: false,
  ssoLoginOptions: [],
  onSSOLoginSuccess: f => f,
  onSSOLoginFail: f => f,
  thereErrorMessage: Texts.ERROR_TRY_AGAIN,
  isOnboardingFlow: false,
  getUserSSOStatus: f => f,
  isOnboardingTestVariantReversed: false
};
