import { AllowedPlatforms, PlatformTypes } from '@jotforminc/constants';
import { getRootPath, getBaseURL } from '@jotforminc/router-bridge';
import { featureFlag } from '@jotforminc/feature-flag';

const cache = {};

export const isPlatformEnv = () => {
  if (typeof cache.isPlatform !== 'undefined') return cache.isPlatform;
  const platformEnv = window.__jfrouter ? window.__jfrouter.PLATFORM_ENV : window.PLATFORM_ENV;
  const isAllowedPlatform = AllowedPlatforms.includes(platformEnv);
  cache.isPlatform = isAllowedPlatform;
  cache.platformEnv = isAllowedPlatform ? platformEnv : null;
  return cache.isPlatform;
};

export const isSalesforceEnv = () => {
  if (isPlatformEnv()) {
    return cache.platformEnv === PlatformTypes.SALESFORCE;
  }
  return false;
};

export const isAnywhereEnv = () => {
  return cache.platformEnv === PlatformTypes.ANYWHERE;
};

export const getPlatformEnv = () => {
  if (isPlatformEnv()) {
    return cache.platformEnv;
  }
  return null;
};

const getValidURL = (url, baseURL) => {
  try {
    return new URL(url, baseURL);
  } catch (e) {
    return false;
  }
};

const appendRootPath = pathname => {
  const rootPath = getRootPath();
  if (!pathname.startsWith(rootPath)) {
    return `${pathname.startsWith('/') ? rootPath.slice(0, -1) : rootPath}${pathname}`;
  }
  return pathname;
};

// THIS IS A TEMPORARY FUNCTION WILL BE DELETED AFTER RELEASE. THANKS AND SORRY :)
const interceptURLForWorkflowRelease = (url = '') => {
  const pathnames = url?.split('/').filter(Boolean) || [];
  const shouldChangeParameter = window?.isWorkflowReleased && pathnames[0] === 'myapprovals';
  if (!shouldChangeParameter) return url;
  return ['/myworkflows', ...pathnames.slice(1)].join('/');
};

const prepareURLWithRoot = urlString => {
  const baseURL = getBaseURL();
  let url = urlString;
  const urlObject = getValidURL(url);

  if (urlObject) {
    if (urlObject.origin === baseURL) {
      urlObject.pathname = appendRootPath(urlObject.pathname);
      url = urlObject.toString();
    }
  } else {
    url = appendRootPath(url);
  }
  url = interceptURLForWorkflowRelease(url);
  return url;
};

const shouldRedirectToSessionExpired = redirectURL => {
  if (isPlatformEnv()) {
    if (
      redirectURL.pathname === '/login'
      && (redirectURL.searchParams.has('rp') || redirectURL.searchParams.size === 0)
    ) {
      return true;
    }
  }
  return false;
};

export const handleCustomNavigation = (url, target = '_blank', dontOverwrite = false, windowFeatures = '') => {
  const isMobileApp = global.navigator.userAgent.indexOf('JotForm Mobile') > -1 || global.navigator.userAgent.indexOf('JFCEMobile') > -1;
  const redirectURL = getValidURL(url, getBaseURL());

  if (redirectURL && shouldRedirectToSessionExpired(redirectURL)) {
    return window.open(`/platform/${getPlatformEnv()}/platform-pages/session-expired${redirectURL.search}`, '_self', windowFeatures);
  }

  if (dontOverwrite) {
    return window.open(url, target, windowFeatures);
  }

  if (isMobileApp) {
    window.location.href = url;
    return;
  }
  if (isPlatformEnv()) {
    if (featureFlag.getFeature(featureFlag.getList().NAVIGATE_TO_THE_NEW_TAB)) {
      return window.open(url, '_blank', `noopener,noreferrer,${windowFeatures}`);
    }

    if (url.startsWith('https://')) {
      // TODO: handle this properly
      return window.open(url, target, windowFeatures);
    }
    return window.open(`/platform/${getPlatformEnv()}${url}`, '_self', windowFeatures);
  }

  return window.open(prepareURLWithRoot(url), target, windowFeatures);
};

export const getPlatformSafeUrl = url => {
  let safeUrl = getValidURL(url);
  if (!safeUrl || (safeUrl && window.__jfrouter.BASE_URL !== (new URL(safeUrl)).origin)) {
    const platformEnv = getPlatformEnv();
    const platformPrefix = platformEnv ? `/platform/${platformEnv}` : '';
    safeUrl = `${window.__jfrouter.BASE_URL}${platformPrefix}/myforms`;
  }
  return safeUrl;
};
