import React, { useMemo, useRef } from 'react';
import {
  elementType, func, bool, node, oneOfType, string, number
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { Modal as UIKitModal, Hooks } from '@jotforminc/uikit';

import { Button } from '@jotforminc/magnet';
import { IconChevronLeft, IconXmark } from '@jotforminc/svg-icons';

import {
  ScOverlay, ScBox, ScHeader, ScFooter
} from './styled';

const Modal = React.forwardRef((props, ref) => {
  const {
    header,
    footer,
    onClose,
    isFullScreen,
    showGoBackAsClose,
    contentRenderer,
    className,
    headerHasCloseButton,
    usePortal,
    headerVisible,
    portalContainerSelector,
    onHeaderCloseClick,
    is2FAModal,
    maxWidth
  } = props;
  const innerRef = useRef();
  const rootRef = Hooks.useCombinedRefs(innerRef, ref);

  // TODO :: We should translate `Go Back` text

  const handleHeaderClose = () => {
    if (onHeaderCloseClick) {
      onHeaderCloseClick();
      return;
    }
    rootRef.current.hide();
  };

  const HeaderRenderer = useMemo(() => () => (
    <ScHeader withHeader={Boolean(header)}>
      <div className="header-content">{header}</div>
      {headerHasCloseButton && (
        <Button
          className="close-modal"
          startIcon={showGoBackAsClose ? IconChevronLeft : IconXmark}
          colorStyle="secondary"
          rounded={showGoBackAsClose ? false : true}
          onClick={handleHeaderClose}
          aria-label={showGoBackAsClose ? t('Go back') : t('Close')}
        >
          {showGoBackAsClose ? t('Go back') : ''}
        </Button>
      )}
    </ScHeader>
  ), [rootRef.current, header]);

  const FooterRenderer = useMemo(() => () => (footer ? <ScFooter data-sc='footer'>{footer}</ScFooter> : null), [footer]);
  const ScOverlayWithClass = useMemo(() => pr => (
    <ScOverlay
      className={className} data-sc='overlay'
      isFullScreen={isFullScreen}
      is2FAModal={is2FAModal}
      maxWidth={maxWidth}
      {...pr}
    />
  ), [isFullScreen, className, is2FAModal]);

  const isMobile = window.navigator.userAgent.indexOf('Mobile') !== -1;

  return (
    <UIKitModal
      closeOnOutsideClick={!isMobile}
      {...props}
      ref={rootRef}
      usePortal={usePortal}
      portalContainerSelector={portalContainerSelector}
      ContentRenderer={contentRenderer}
      DialogRenderer={ScOverlayWithClass}
      HeaderRenderer={headerVisible ? HeaderRenderer : () => null}
      FooterRenderer={FooterRenderer}
      onModalClose={onClose}
    />
  );
});

Modal.propTypes = {
  header: oneOfType([elementType, node]),
  footer: oneOfType([elementType, node]),
  onClose: func,
  onHeaderCloseClick: func,
  isFullScreen: bool,
  showGoBackAsClose: bool,
  className: string,
  contentRenderer: oneOfType([elementType, node]),
  headerHasCloseButton: bool,
  usePortal: bool,
  headerVisible: bool,
  portalContainerSelector: string,
  is2FAModal: bool,
  maxWidth: number
};

Modal.defaultProps = {
  header: null,
  footer: null,
  onClose: f => f,
  onHeaderCloseClick: undefined,
  isFullScreen: false,
  showGoBackAsClose: false,
  className: undefined,
  contentRenderer: ScBox,
  headerHasCloseButton: true,
  usePortal: false,
  headerVisible: true,
  portalContainerSelector: 'body',
  is2FAModal: false,
  maxWidth: 630
};

export default Modal;
