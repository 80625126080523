import isElement from 'lodash/isElement';

export const createFormData = data => {
  return Object.keys(data)
    .map(key => `${key}=${window.encodeURIComponent(data[key])}`)
    .join('&');
};

export const listenEnter = (e, callbackFN = () => undefined, stopPropagation = true) => {
  const { key = '', keyCode } = e;
  if ((key && key === 'Enter') || keyCode === 13) {
    if (stopPropagation) {
      e.stopPropagation(); // to prevent cardform selectNextQuestion
    }
    callbackFN();
  }
};

export const loadJS = (id, src, onload) => {
  if (document.getElementById(id)) {
    return false;
  }

  const js = document.createElement('script');

  js.id = id;
  js.onload = onload;
  js.src = src;
  const fjs = document.getElementsByTagName('script')[0];
  if (fjs) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    document.getElementsByTagName('head')[0].appendChild(js);
  }
};

/**
 * Select nearest parent matches with selector (unless it encounters stopSelector) according to _element.
 * @param _element
 * @param selector
 * @param stopSelector
 * @returns {*}
 */
export const closest = (_element, selector, stopSelector) => {
  let element = _element;
  let retval = null;
  while (element) {
    if (element.matches(selector)) {
      retval = element;
      break;
    } else if (stopSelector && element.matches(stopSelector)) {
      break;
    }
    element = element.parentElement;
  }
  return retval;
};

// Check if node2 contains node1
export const nodeContains = (node2, node1) => isElement(node2) && isElement(node1) && ((node2 === node1) || nodeContains(node2, node1.parentNode));

export const getClassAttributes = values => {
  if (!values?.class) return '';
  const classAttributes = values.class;
  const classResult = Object.values(classAttributes).join(' ').trim();
  return classResult;
};

/**
 * Select the text content and collapse if you want to move the caret to end of input
 * @param target
 * @param shouldCollapse
 */
export const selectAllText = ({ target, shouldCollapse = false, focusBefore = false }) => {
  let selection;
  let range;
  if (focusBefore) target.focus();

  if (document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(target);
    if (shouldCollapse) range.collapse(false);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(target);
    selection.removeAllRanges();
    if (shouldCollapse) range.collapse(false);
    selection.addRange(range);
  }

  if (!focusBefore) target.focus();
};

export const classNameGenerator = classess => {
  let className = '';
  const classKeys = Object.keys(classess);
  classKeys.forEach(classKey => {
    if (classess[classKey]) className += ` ${classKey}`;
  });
  return className.trim();
};
