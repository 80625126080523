import { createPortal } from 'react-dom';
import { bool, string, node } from 'prop-types';
import { forwardRef, useImperativeHandle } from 'react';

import { usePortalRef } from '../../utils/hooks';

const Portal = forwardRef(({
  children,
  usePortal,
  portalAttribute,
  containerSelector,
  initialPortalSelector
}, ref) => {
  const portalRef = usePortalRef(containerSelector, usePortal, portalAttribute, initialPortalSelector);

  useImperativeHandle(ref, () => ({
    portalID: portalRef.current ? portalRef.current.id : ''
  }));

  if (usePortal && portalRef.current) {
    return createPortal(
      children,
      portalRef.current
    );
  }

  return children;
});

Portal.propTypes = {
  children: node.isRequired,
  usePortal: bool,
  containerSelector: string,
  portalAttribute: string,
  initialPortalSelector: string
};

Portal.defaultProps = {
  usePortal: true,
  containerSelector: 'body',
  portalAttribute: undefined,
  initialPortalSelector: ''
};

export default Portal;
